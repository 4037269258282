import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/layout/layout'

import './form.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = { 
      isValidated: false,
      focusedInput: null,
    }
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onFocusChange(focusedInput) {
    this.setState({
      focusedInput,
    })
  }

 
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const button = document.querySelector('button.submit');
    button.innerHTML = 'Moment geduld a.u.b...';
    button.disabled = true;
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <main>
          <section>

            <div className="page contact">
            
              <h1>Contact</h1>
            
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" aria-label="form name" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" aria-label="field for bot" onChange={this.handleChange} />
                  </label>
                </div>

                  <fieldset>
                    
                    <legend>Uw gegevens</legend>
                    <div className="control">
                      <input required={true} onChange={this.handleChange} aria-label="aanhef" className="input" type="radio" id="dhr" name="aanhef" value="Dhr."/>
                      <label htmlFor="dhr">Dhr.</label>
                      <input required={true} onChange={this.handleChange} aria-label="aanhef" className="input" type="radio" id="mevr" name="aanhef" value="Mevr."/>
                      <label htmlFor="mevr">Mevr.</label>
                      <input required={true} onChange={this.handleChange} aria-label="aanhef" className="input" type="radio" id="familie" name="aanhef" value="Familie"/>
                      <label htmlFor="familie">Familie</label>
                    </div>
                    <label className="label" htmlFor={'voornaam'}>
                      Voornaam
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'voornaam'}
                        aria-label="voornaam"
                        onChange={this.handleChange}
                        id={'voornaam'}
                        required={true}
                      />
                    </div>
                    <label className="label" htmlFor={'tussenvoegsel'}>
                      Tussenvoegsel
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'tussenvoegsel'}
                        aria-label="tussenvoegsel"
                        onChange={this.handleChange}
                        id={'tussenvoegsel'}
                      />
                    </div>
                    <label className="label" htmlFor={'achternaam'}>
                      Achternaam
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'achternaam'}
                        aria-label="achternaam"
                        onChange={this.handleChange}
                        id={'achternaam'}
                        required={true}
                      />
                    </div>
                    <label className="label" htmlFor={'email'}>
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        aria-label="email"
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                      />
                    </div>
                    <label className="label" htmlFor={'telefoon'}>
                      Telefoon
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'telefoon'}
                        aria-label="telefoon"
                        onChange={this.handleChange}
                        id={'telefoon'}
                        required={true}
                      />
                    </div>

                    <label className="label" htmlFor={'straatnaam_nummer'}>
                      Straatnaam + nummer
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'straatnaam_nummer'}
                        aria-label="Straatnaam + nummer"
                        onChange={this.handleChange}
                        id={'telefoon'}
                        required={true}
                      />
                    </div>

                    <label className="label" htmlFor={'postcode'}>
                      Postcode
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'postcode'}
                        aria-label="Postcode"
                        onChange={this.handleChange}
                        id={'postcode'}
                        required={true}
                      />
                    </div>

                    <label className="label" htmlFor={'plaats'}>
                      Plaats
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'plaats'}
                        aria-label="Plaats"
                        onChange={this.handleChange}
                        id={'plaats'}
                        required={true}
                      />
                    </div>

                  </fieldset>

                  <fieldset>
                    
                  <legend>Opmerkingen</legend>
                    
                  <div className="control">
                    <textarea
                      className="input"
                      name={'opmerkingen'}
                      aria-label="Opmerkingen"
                      onChange={this.handleChange}
                      id={'opmerkingen'}
                    />
                  </div>
                  </fieldset>

                  <button className="button submit" type="submit">Versturen</button>

              </form>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}
